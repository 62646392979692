import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const PublicRoute = ({ children }) => {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Проверяем, есть ли активный процесс редиректа
  if (sessionStorage.getItem('redirecting')) {
    console.log('Публичный маршрут: процесс редиректа уже запущен, пропускаем перенаправление');
    return <div>Перенаправление...</div>;
  }

  // Если пользователь авторизован, перенаправляем на dashboard
  if (user) {
    console.log('Публичный маршрут: пользователь авторизован, перенаправляем на dashboard');
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default PublicRoute; 