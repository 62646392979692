import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import Client from 'paho-mqtt';
import events from '../utils/events'; // Добавляем импорт events

// Включение логирования для отладки
const DEBUG = false; // Отключаем отладочные логи

// Функция логирования
const mqttLog = (message, data) => {
    if (DEBUG) {
        if (data) {
            console.log(`[MQTTContext] ${message}`, data);
        } else {
            console.log(`[MQTTContext] ${message}`);
        }
    }
};

// Создаем контекст
const MQTTContext = createContext();

// Хук для использования MQTT контекста
export const useMQTT = () => {
    const context = useContext(MQTTContext);
    if (!context) {
        throw new Error('useMQTT должен использоваться внутри MQTTProvider');
    }
    return context;
};

// Определяем URL для MQTT в зависимости от окружения
const getMQTTUrl = () => {
    // Для продакшена используем WSS через домен
    if (window.location.hostname === 'fastt.kz') {
        return 'wss://fastt.kz/mqtt';
    }
    // Для локальной разработки используем WS через IP или localhost
    // Замените на ваш локальный IP или 'localhost' при необходимости
    const localBrokerIp = '195.49.215.60'; // Или IP вашего MQTT брокера в Docker сети
    return `ws://${localBrokerIp}:9001/mqtt`; 
};

// Проверка находимся ли на странице авторизации
const isAuthPage = (path) => {
    return path === '/login' || path === '/register' || path.includes('/auth');
};

export const MQTTProvider = ({ children }) => {
    // Состояние клиента MQTT
    const [connected, setConnected] = useState(false);
    const [error, setError] = useState(null);
    const clientRef = useRef(null);
    const [reconnectAttempt, setReconnectAttempt] = useState(0);
    const reconnectTimerRef = useRef(null);
    const connectionInProgressRef = useRef(false);
    const MAX_RECONNECT_ATTEMPTS = 5;
    const RECONNECT_DELAYS = [2000, 4000, 8000, 16000, 30000]; // Задержки между попытками в мс
    const [currentPathname, setCurrentPathname] = useState(window.location.pathname);
    
    // Функция инициализации и подключения клиента MQTT
    const connectClient = useCallback(() => {
        mqttLog('Вызов connectClient');
        
        // Предотвращаем множественные попытки подключения
        if (connectionInProgressRef.current) {
            mqttLog('Пропуск connectClient - подключение уже в процессе');
            return null;
        }
        
        // Проверяем, находимся ли мы на странице логина/регистрации
        const currentPath = window.location.pathname;
        if (isAuthPage(currentPath)) {
            mqttLog('Пропуск connectClient на странице авторизации');
            return null;
        }
        
        mqttLog('Старт процесса подключения...');
        connectionInProgressRef.current = true;
        setError(null); // Сбрасываем ошибку перед новой попыткой

        try {
            const mqttUrl = getMQTTUrl();
            const clientId = `web_${Math.random().toString(16).substring(2, 8)}`;
            
            mqttLog(`Параметры подключения: URL=${mqttUrl}, ClientID=${clientId}`);
            
            // --- ЛОГ ПЕРЕД СОЗДАНИЕМ КЛИЕНТА ---
            mqttLog('Создание экземпляра Paho.Client...');
            const newClient = new Client.Client(mqttUrl, clientId);
            mqttLog('Экземпляр Paho.Client создан');
            
            clientRef.current = newClient; // Сохраняем ссылку на клиент
            
            newClient.onConnectionLost = (responseObject) => {
                mqttLog('MQTT соединение потеряно', responseObject);
                if (responseObject.errorCode !== 0) {
                    mqttLog(`Код ошибки: ${responseObject.errorCode}, Сообщение: ${responseObject.errorMessage}`);
                    setError(`MQTT Потеряно: ${responseObject.errorMessage} (код ${responseObject.errorCode})`);
                }
                setConnected(false);
                connectionInProgressRef.current = false;
                // --- УПРОЩЕННАЯ ЛОГИКА ПЕРЕПОДКЛЮЧЕНИЯ (пока закомментировано) ---
                /* 
                if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
                    const delay = Math.pow(2, reconnectAttempt) * 1000; // Экспоненциальная задержка
                    mqttLog(`Попытка переподключения #${reconnectAttempt + 1} через ${delay / 1000} сек...`);
                    if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
                    reconnectTimerRef.current = setTimeout(() => {
                        setReconnectAttempt(prev => prev + 1);
                        connectClient();
                    }, delay);
                } else {
                    mqttLog('Достигнуто максимальное количество попыток подключения');
                    setError('Не удалось подключиться к MQTT брокеру после нескольких попыток');
                }
                */
            };
            
            newClient.onMessageArrived = (message) => {
                mqttLog(`Получено сообщение MQTT: Топик=${message.destinationName}`, message.payloadString);
                try {
                    // Безопасный разбор JSON или использование строкового сообщения если не JSON
                    let data;
                    try {
                        data = JSON.parse(message.payloadString);
                    } catch (jsonError) {
                        data = message.payloadString;
                        mqttLog('Сообщение не является JSON, используем как строку', data);
                    }
                    
                    const topic = message.destinationName;
                    
                    // Проверка, что events определен перед использованием
                    if (events && typeof events.emit === 'function') {
                        events.emit('mqtt_message', { topic, data });
                    } else {
                        mqttLog('Предупреждение: events не определен или не содержит метод emit', null);
                    }
                } catch (e) {
                    mqttLog('Ошибка обработки MQTT сообщения', e);
                }
            };
            
            const connectOptions = {
                timeout: 15, // Увеличим таймаут
                keepAliveInterval: 60,
                cleanSession: true,
                useSSL: mqttUrl.startsWith('wss'),
                onSuccess: () => {
                    mqttLog('>>> MQTT ПОДКЛЮЧЕНИЕ УСПЕШНО УСТАНОВЛЕНО <<<');
                    setConnected(true);
                    setReconnectAttempt(0); // Сброс счетчика при успехе
                    connectionInProgressRef.current = false;
                    setError(null);
                    
                    const topicsToSubscribe = [
                        'charging/+/status',
                        'charging/+/telemetry',
                        'charging/+/lwt',
                        'charging/server/status'
                    ];
                    
                    topicsToSubscribe.forEach(topic => {
                        mqttLog(`Подписка на топик: ${topic}`);
                        newClient.subscribe(topic, { qos: 0 });
                    });
                },
                onFailure: (message) => {
                    mqttLog('>>> ОШИБКА ПОДКЛЮЧЕНИЯ К MQTT <<<', message);
                    setError(`MQTT Ошибка: ${message.errorMessage} (код ${message.errorCode})`);
                    setConnected(false);
                    connectionInProgressRef.current = false;
                    // --- УПРОЩЕННАЯ ЛОГИКА ПЕРЕПОДКЛЮЧЕНИЯ (пока закомментировано) ---
                    /* 
                    if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
                         const delay = Math.pow(2, reconnectAttempt) * 1000;
                         mqttLog(`Попытка переподключения #${reconnectAttempt + 1} через ${delay / 1000} сек (из onFailure)...`);
                         if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
                         reconnectTimerRef.current = setTimeout(() => {
                             setReconnectAttempt(prev => prev + 1);
                             connectClient();
                         }, delay);
                    } else {
                         mqttLog('Достигнуто максимальное количество попыток подключения (из onFailure)');
                    }
                    */
                }
            };
            
            // --- ЛОГ ПЕРЕД ВЫЗОВОМ CONNECT ---
            mqttLog('Вызов newClient.connect() с опциями:', connectOptions);
            newClient.connect(connectOptions);
            // --- ЛОГ СРАЗУ ПОСЛЕ ВЫЗОВА CONNECT ---
            mqttLog('Вызов newClient.connect() завершен (не означает успех подключения)');

        } catch (error) {
            mqttLog('КРИТИЧЕСКАЯ ОШИБКА в connectClient (try/catch)', error);
            setError(`Критическая ошибка MQTT: ${error.message}`);
            setConnected(false);
            connectionInProgressRef.current = false;
        }

    }, [reconnectAttempt]); // Оставляем зависимость, чтобы сброс работал
    
    useEffect(() => {
        // --- ЛОГ В САМОМ НАЧАЛЕ useEffect ---
        mqttLog('>>> useEffect в MQTTProvider ЗАПУЩЕН (без зависимости) <<<');
        
        const currentPath = window.location.pathname;
        // --- ЛОГ ТЕКУЩЕГО ПУТИ ---
        mqttLog(`useEffect: Текущий путь: ${currentPath}`);
        
        if (isAuthPage(currentPath)) {
            mqttLog('useEffect: Пропуск вызова connectClient на странице авторизации');
        } else {
            // --- ЛОГ ПЕРЕД ВЫЗОВОМ connectClient ---
            mqttLog('useEffect: Вызов connectClient...');
            connectClient();
            mqttLog('useEffect: Вызов connectClient завершен.');
        }
        
        return () => {
            mqttLog('Отключение MQTT клиента - useEffect cleanup');
            if (reconnectTimerRef.current) {
                clearTimeout(reconnectTimerRef.current);
            }
            if (clientRef.current && clientRef.current.isConnected()) {
                 try {
                     mqttLog('Вызов disconnect()...');
                     clientRef.current.disconnect();
                     mqttLog('disconnect() вызван.');
                 } catch (e) {
                     mqttLog('Ошибка при вызове disconnect():', e);
                 }
            } else {
                mqttLog('Клиент не был подключен, пропускаем disconnect().')
            }
            clientRef.current = null; // Очищаем ссылку
            connectionInProgressRef.current = false; // Сбрасываем флаг
        };
    }, []); // Пустой массив зависимостей - выполнится 1 раз при монтировании
    
    // Функция публикации сообщения
    const publish = useCallback((topic, message) => {
        if (!clientRef.current || !connected) {
            mqttLog('Невозможно опубликовать - нет подключения');
            return false;
        }
        
        try {
            mqttLog(`Публикация в топик ${topic}:`, message);
            const payload = typeof message === 'string' ? message : JSON.stringify(message);
            const mqttMessage = new Client.Message(payload);
            mqttMessage.destinationName = topic;
            clientRef.current.send(mqttMessage);
            return true;
        } catch (error) {
            mqttLog('Ошибка при публикации:', error);
            setError(`Ошибка отправки сообщения: ${error.message}`);
            return false;
        }
    }, [connected]);
    
    // Функция подписки на топик
    const subscribe = useCallback((topic, callback) => {
        if (!clientRef.current || !connected) {
            mqttLog(`Невозможно подписаться на ${topic} - нет подключения`);
            return false;
        }
        
        try {
            mqttLog(`Подписка на топик: ${topic}`);
            
            // Функция-обработчик сообщений для данного топика
            const messageHandler = (message) => {
                if (message.destinationName.match(new RegExp(topic.replace('+', '[^/]+')))) {
                    try {
                        let payload;
                        try {
                            payload = JSON.parse(message.payloadString);
                        } catch (e) {
                            payload = message.payloadString;
                        }
                        
                        callback({
                            topic: message.destinationName,
                            payload
                        });
                    } catch (error) {
                        mqttLog(`Ошибка обработки сообщения для ${topic}:`, error);
                    }
                }
            };
            
            // Сохраняем обработчик, чтобы его можно было потом отменить
            clientRef.current._handlers = clientRef.current._handlers || {};
            clientRef.current._handlers[topic] = messageHandler;
            
            // Регистрируем обработчик
            const originalHandler = clientRef.current.onMessageArrived;
            clientRef.current.onMessageArrived = (message) => {
                // Сначала вызываем оригинальный обработчик, если он был
                if (originalHandler) {
                    originalHandler(message);
                }
                
                // Вызываем все подходящие обработчики
                for (const t in clientRef.current._handlers) {
                    clientRef.current._handlers[t](message);
                }
            };
            
            // Подписываемся на топик
            clientRef.current.subscribe(topic);
            
            return true;
        } catch (error) {
            mqttLog(`Ошибка при подписке на ${topic}:`, error);
            return false;
        }
    }, [connected]);
    
    // Функция отписки от топика
    const unsubscribe = useCallback((topic) => {
        if (!clientRef.current || !connected) {
            mqttLog(`Невозможно отписаться от ${topic} - нет подключения`);
            return false;
        }
        
        try {
            mqttLog(`Отписка от топика: ${topic}`);
            
            // Удаляем обработчик
            if (clientRef.current._handlers && clientRef.current._handlers[topic]) {
                delete clientRef.current._handlers[topic];
            }
            
            // Отписываемся от топика
            clientRef.current.unsubscribe(topic);
            
            return true;
        } catch (error) {
            mqttLog(`Ошибка при отписке от ${topic}:`, error);
            return false;
        }
    }, [connected]);
    
    // Функция для ручного переподключения (доступна извне)
    const manualReconnect = useCallback(() => {
        // Проверяем, находимся ли мы на странице логина
        const currentPath = window.location.pathname;
        if (currentPath === '/login' || currentPath === '/register') {
            mqttLog('Пропуск переподключения MQTT на странице авторизации');
            return null;
        }
        
        // Сбрасываем счетчик попыток и пробуем подключиться заново
        setReconnectAttempt(0);
        return connectClient();
    }, [connectClient]);
    
    // Обработчик изменения маршрута
    useEffect(() => {
        const handleRouteChange = () => {
            const newPath = window.location.pathname;
            mqttLog(`Обнаружено изменение маршрута: ${currentPathname} -> ${newPath}`);
            setCurrentPathname(newPath);
            
            // Если переход со страницы авторизации на другую страницу
            if (isAuthPage(currentPathname) && !isAuthPage(newPath)) {
                mqttLog('Переход с авторизации на основную страницу - инициируем подключение к MQTT');
                if (!connected && !connectionInProgressRef.current) {
                    connectClient();
                }
            } 
            // Если переход на страницу авторизации с другой страницы
            else if (!isAuthPage(currentPathname) && isAuthPage(newPath)) {
                mqttLog('Переход на страницу авторизации - отключаем MQTT');
                if (clientRef.current && clientRef.current.isConnected()) {
                    try {
                        clientRef.current.disconnect();
                        mqttLog('MQTT клиент отключен при переходе на страницу авторизации');
                    } catch (e) {
                        mqttLog('Ошибка при отключении MQTT клиента:', e);
                    }
                    setConnected(false);
                }
            }
        };
        
        // Добавление обработчика изменения истории
        window.addEventListener('popstate', handleRouteChange);
        
        // Перехват стандартных переходов по ссылкам
        const originalPushState = history.pushState;
        history.pushState = function() {
            originalPushState.apply(this, arguments);
            handleRouteChange();
        };
        
        return () => {
            window.removeEventListener('popstate', handleRouteChange);
            history.pushState = originalPushState;
        };
    }, [connected, currentPathname]);
    
    // Значение контекста для провайдера
    const mqttContext = {
        client: clientRef.current,
        connected,
        error,
        publish,
        subscribe,
        unsubscribe,
        reconnect: manualReconnect,
        reconnectAttempt,
        maxReconnectAttempts: MAX_RECONNECT_ATTEMPTS,
        isConnected: connected
    };
    
    return (
        <MQTTContext.Provider value={mqttContext}>
            {children}
        </MQTTContext.Provider>
    );
}; 