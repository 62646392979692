import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { userApi } from '../services/api';

const UserContext = createContext(null);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tokenError, setTokenError] = useState(false);
  const [error, setError] = useState(null);
  const [lastValidationTime, setLastValidationTime] = useState(0);

  // Функция для валидации данных пользователя
  const validateUserData = (userData) => {
    if (!userData) return false;
    
    // Проверяем обязательные поля
    const requiredFields = ['id', 'role', 'email', 'phone'];
    const hasAllFields = requiredFields.every(field => userData.hasOwnProperty(field));
    if (!hasAllFields) return false;

    // Проверяем валидность роли
    const validRoles = ['admin', 'user', 'guest'];
    if (!validRoles.includes(userData.role)) return false;

    return true;
  };

  // Функция для безопасной установки пользователя
  const setUserSafely = (userData) => {
    if (!validateUserData(userData)) {
      console.error('Попытка установить невалидные данные пользователя');
      logout();
      return false;
    }
    setUser(userData);
    return true;
  };

  // Функция для проверки и обновления авторизации
  const checkAuth = useCallback(async () => {
    try {
      setIsLoading(true);
      
      // Проверяем наличие активного редиректа
      if (sessionStorage.getItem('redirecting')) {
        console.log('UserContext: процесс редиректа уже запущен, пропускаем проверку авторизации');
        setIsLoading(false);
        return;
      }
      
      // Проверяем флаг принудительного обновления
      const forceReload = localStorage.getItem('forceReload');
      if (forceReload) {
        console.log('UserContext: обнаружен флаг принудительной перезагрузки, выполняем очистку кэша');
        localStorage.removeItem('forceReload');
        
        // Не удаляем userData если у нас есть валидный токен
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          localStorage.removeItem('userData');
        }
        
        if ('caches' in window) {
          const cacheKeys = await window.caches.keys();
          await Promise.all(
            cacheKeys.map(key => window.caches.delete(key))
          );
        }
      }
      
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        setUser(null);
        setIsLoading(false);
        return;
      }

      // Добавляем случайный параметр для предотвращения кэширования
      const timestamp = Date.now();
      const response = await fetch(`/api/users/me?_t=${timestamp}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });

      if (!response.ok) {
        // Если мы уже на странице логина/регистрации, не делаем редирект
        const currentPath = window.location.pathname;
        if (currentPath === '/login' || currentPath === '/register') {
          console.log('UserContext: ошибка авторизации на странице авторизации, пропускаем редирект');
          setUser(null);
          setIsLoading(false);
          return;
        }
        
        // Предотвращаем двойной редирект
        if (sessionStorage.getItem('redirecting')) {
          console.log('UserContext: процесс редиректа уже запущен, пропускаем');
          setUser(null);
          setIsLoading(false);
          return;
        }
        
        throw new Error('Unauthorized');
      }

      const userData = await response.json();
      setUser(userData);
      localStorage.setItem('userData', JSON.stringify(userData));
      
    } catch (error) {
      console.error('Ошибка при проверке авторизации:', error);
      
      // Проверяем, находимся ли мы на странице логина
      const currentPath = window.location.pathname;
      if (currentPath === '/login' || currentPath === '/register') {
        console.log('UserContext: ошибка авторизации на странице авторизации, пропускаем редирект');
        setUser(null);
        setIsLoading(false);
        return;
      }
      
      // Проверяем, есть ли редирект в процессе
      if (sessionStorage.getItem('redirecting')) {
        console.log('UserContext: процесс редиректа уже запущен, пропускаем повторный редирект');
        setUser(null);
        setIsLoading(false);
        return;
      }
      
      // При ошибке очищаем только нужные данные
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('userData');
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Проверяем авторизацию при монтировании компонента
  useEffect(() => {
    console.log('UserProvider: инициализация');
    checkAuth();

    // Устанавливаем слушатель для обновления токена
    const handleStorageChange = (e) => {
      if (e.key === 'jwtToken') {
        console.log('UserProvider: изменение JWT токена');
        if (e.newValue) {
          checkAuth();
        } else if (!e.newValue) {
          setUser(null);
          setTokenError(true);
        }
      } else if (e.key === 'forcedReload' && e.newValue === 'true') {
        console.log('UserProvider: обнаружен флаг принудительного обновления');
        localStorage.removeItem('forcedReload');
        checkAuth();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [checkAuth]);

  const login = async (credentials) => {
    try {
      setIsLoading(true);
      setError(null);

      // Очищаем флаг редиректа при попытке логина
      sessionStorage.removeItem('redirecting');
      
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        const errorText = await response.text();
        try {
          const errorJson = JSON.parse(errorText);
          setError(errorJson.error || `Ошибка входа: ${response.status}`);
        } catch (e) {
          console.error('Ошибка парсинга JSON ошибки:', e, 'Полученный текст:', errorText);
          setError(`Ошибка входа: ${response.status}`);
        }
        return false;
      }

      const text = await response.text();
      let data;
      try {
        data = JSON.parse(text);
      } catch (e) {
        console.error('Ошибка парсинга JSON:', e, 'Полученный текст:', text);
        setError('Ошибка формата данных с сервера');
        return false;
      }

      if (data.token) {
        localStorage.setItem('jwtToken', data.token);
        const userData = {
          id: data.id,
          name: data.name,
          phone: data.phone,
          email: data.email,
          role: data.role || 'user',
          charging_limit: data.charging_limit || 0,
          daily_usage: data.daily_usage || 0
        };
        
        // Сохраняем данные пользователя в localStorage
        localStorage.setItem('userData', JSON.stringify(userData));
        
        setUser(userData);
        return true;
      } else {
        setError(data.error || 'Не удалось получить токен авторизации');
        return false;
      }
    } catch (err) {
      console.error('Ошибка входа:', err);
      setError('Ошибка соединения с сервером');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      // Проверяем наличие флага активной переадресации
      if (sessionStorage.getItem('redirecting')) {
        console.log('Процесс редиректа уже запущен, пропускаем повторный logout');
        return;
      }
      
      // Устанавливаем флаг редиректа
      sessionStorage.setItem('redirecting', 'true');
      
      // Очищаем все данные пользователя
      localStorage.clear(); // Полная очистка localStorage
      sessionStorage.removeItem('userData'); // Удаляем только данные пользователя
      
      // Очищаем все куки
      document.cookie.split(";").forEach(cookie => {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      
      // Очищаем кэш приложения
      if ('caches' in window) {
        try {
          const cacheKeys = await window.caches.keys();
          await Promise.all(
            cacheKeys.map(key => window.caches.delete(key))
          );
        } catch (e) {
          console.error('Ошибка при очистке кэша:', e);
        }
      }
      
      // Сбрасываем состояние сессий
      if (window.SessionService) {
        window.SessionService.clearAllSessions();
      }
      
      setUser(null);
      setIsLoading(false);
      setLastValidationTime(0);
      
      // Устанавливаем флаг для принудительного обновления при следующей загрузке
      localStorage.setItem('forceReload', 'true');
      
      // После очистки данных перенаправляем пользователя
      setTimeout(() => {
        // Очищаем флаг редиректа перед переходом
        sessionStorage.removeItem('redirecting');
        // Используем replace вместо обычного перехода на страницу логина
        window.location.replace('/login');
      }, 300); // Увеличиваем таймаут для большей стабильности
    } catch (error) {
      console.error('Ошибка при выходе:', error);
      sessionStorage.removeItem('redirecting');
      window.location.replace('/login');
    }
  };

  const register = async (userData) => {
    try {
      const { token, user: newUser } = await userApi.register(userData);
      localStorage.setItem('jwtToken', token);
      
      // Сохраняем данные пользователя в localStorage
      localStorage.setItem('userData', JSON.stringify(newUser));
      
      setUser(newUser);
      setTokenError(false);
      return true;
    } catch (error) {
      console.error('Registration error:', error);
      return false;
    }
  };

  // Функция для проверки актуальности данных пользователя
  const validateSession = async () => {
    try {
      // Проверяем наличие активного редиректа
      if (sessionStorage.getItem('redirecting')) {
        console.log('UserContext: процесс редиректа уже запущен, пропускаем валидацию сессии');
        return;
      }
      
      // Проверяем, находимся ли мы на странице логина
      const currentPath = window.location.pathname;
      if (currentPath === '/login' || currentPath === '/register') {
        console.log('UserContext: находимся на странице авторизации, пропускаем валидацию сессии');
        return;
      }
      
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('No token found');
      }

      // Добавляем временную метку для предотвращения кэширования
      const timestamp = Date.now();
      const response = await fetch(`/api/users/me?_t=${timestamp}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });

      if (!response.ok) {
        throw new Error('Invalid session');
      }

      const userData = await response.json();
      if (!setUserSafely(userData)) {
        throw new Error('Invalid user data');
      }

      setLastValidationTime(Date.now());
    } catch (error) {
      console.error('Session validation failed:', error);
      
      // Проверяем, находимся ли мы на странице логина
      const currentPath = window.location.pathname;
      if (currentPath === '/login' || currentPath === '/register') {
        console.log('UserContext: ошибка валидации на странице авторизации, пропускаем редирект');
        return;
      }
      
      // Проверяем, есть ли редирект в процессе
      if (sessionStorage.getItem('redirecting')) {
        console.log('UserContext: процесс редиректа уже запущен, пропускаем повторный редирект');
        return;
      }
      
      logout();
    }
  };

  // Проверяем сессию при загрузке и периодически
  useEffect(() => {
    validateSession();

    // Проверяем сессию каждые 5 минут
    const intervalId = setInterval(validateSession, 5 * 60 * 1000);

    // Слушаем события хранилища
    const handleStorageChange = (e) => {
      if (e.key === 'jwtToken' || e.key === null) {
        validateSession();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Слушаем изменение видимости вкладки
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        validateSession();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('storage', handleStorageChange);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const value = {
    user,
    isLoading,
    tokenError,
    error,
    login,
    logout,
    register,
    setUser,
    checkAuth,
    setUserSafely
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;