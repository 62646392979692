import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles } from '@mui/material';
import theme from './theme';
import { MQTTProvider } from './context/MQTTContext';
import AppRoutes from './routes';
import { SessionService } from './services/sessionService';

// Инициализируем SessionService при запуске приложения
SessionService.init();

// Добавляем обработчик ошибок
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Ошибка приложения:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ 
                    padding: '20px', 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <h2>Что-то пошло не так</h2>
                    <p>Произошла ошибка при загрузке приложения.</p>
                    <button 
                        onClick={() => window.location.href = '/'}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#1976d2',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            marginTop: '20px'
                        }}
                    >
                        Вернуться на главную
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}

// Функция для добавления антикеш-заголовков при загрузке страницы
function setAntiCacheHeaders() {
    // Добавляем мета-теги для контроля кеширования
    let metaCache = document.createElement('meta');
    metaCache.httpEquiv = 'Cache-Control';
    metaCache.content = 'no-cache, no-store, must-revalidate';
    document.head.appendChild(metaCache);

    let metaPragma = document.createElement('meta');
    metaPragma.httpEquiv = 'Pragma';
    metaPragma.content = 'no-cache';
    document.head.appendChild(metaPragma);

    let metaExpires = document.createElement('meta');
    metaExpires.httpEquiv = 'Expires';
    metaExpires.content = '0';
    document.head.appendChild(metaExpires);
}

// Глобальные стили для модальных окон
const modalStyles = {
    '.MuiDialog-root': {
        zIndex: 1400,
    },
    '.MuiBackdrop-root': {
        zIndex: -1,
    },
    '.MuiModal-root': {
        '&:not(:first-of-type)': {
            zIndex: 1450,
        }
    },
    // Предотвращаем смещение контента при открытии модальных окон
    body: {
        '&.MuiDialog-open': {
            paddingRight: '0 !important',
            overflow: 'auto !important',
        }
    }
};

function App() {
    // Обнаруживаем необходимость принудительной перезагрузки
    useEffect(() => {
        console.log('App: инициализация');

        // Проверяем наличие активного редиректа при запуске
        if (sessionStorage.getItem('redirecting')) {
            console.log('App: обнаружен процесс редиректа при инициализации, удаляем флаг');
            // Удаляем флаг, чтобы избежать проблем при перезагрузке страницы
            setTimeout(() => {
                sessionStorage.removeItem('redirecting');
            }, 500);
        }
        
        // Устанавливаем антикеш-заголовки
        setAntiCacheHeaders();
        
        // Проверяем флаг принудительного обновления
        const forcedReload = localStorage.getItem('forcedReload');
        if (forcedReload === 'true') {
            console.log('Обнаружен флаг принудительного обновления в App, очищаем флаг');
            localStorage.removeItem('forcedReload');
            
            // Проверяем - если мы не на странице логина, перезагружаем
            if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
                console.log('Не на странице логина/регистрации, проверяем токен');
                
                // Проверяем наличие токена для определения дальнейших действий
                const token = localStorage.getItem('jwtToken');
                
                // Устанавливаем флаг для предотвращения циклического редиректа
                sessionStorage.setItem('redirecting', 'true');
                
                if (token) {
                    // Есть токен - перенаправляем на dashboard
                    console.log('Токен найден, перенаправляем на dashboard');
                    setTimeout(() => {
                        sessionStorage.removeItem('redirecting');
                        window.location.replace('/dashboard');
                    }, 300);
                } else {
                    // Нет токена - перенаправляем на login
                    console.log('Токен не найден, перенаправляем на login');
                    setTimeout(() => {
                        sessionStorage.removeItem('redirecting');
                        window.location.replace('/login');
                    }, 300);
                }
            } else {
                console.log('Находимся на странице авторизации, пропускаем редирект');
            }
            return;
        }
        
        // Создаем функцию для проверки изменений JWT токена
        const checkJwtChanges = () => {
            const jwt = localStorage.getItem('jwtToken');
            if (jwt) {
                console.log('App: JWT токен обнаружен, проверяем данные пользователя');
                const userData = localStorage.getItem('userData');
                if (!userData) {
                    console.log('App: данные пользователя отсутствуют, устанавливаем флаг для перезагрузки');
                    
                    // Проверяем наличие процесса редиректа
                    if (sessionStorage.getItem('redirecting')) {
                        console.log('App: процесс редиректа уже запущен, пропускаем дополнительные действия');
                        return;
                    }
                    
                    // Если мы на странице логина, не устанавливаем флаг
                    if (window.location.pathname === '/login' || window.location.pathname === '/register') {
                        console.log('App: находимся на странице авторизации, пропускаем установку флага');
                        return;
                    }
                    
                    localStorage.setItem('forcedReload', 'true');
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 300);
                }
            }
        };
        
        // Запускаем проверку сразу и устанавливаем интервал
        checkJwtChanges();
        
        // Устанавливаем обработчик события перед выгрузкой страницы
        window.addEventListener('beforeunload', () => {
            const forcedReload = localStorage.getItem('forcedReload');
            if (forcedReload === 'true') {
                // Очищаем кеш Service Worker при принудительной перезагрузке
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.getRegistrations().then(registrations => {
                        for (let registration of registrations) {
                            registration.unregister();
                        }
                    });
                }
                
                // Очищаем кеш браузера
                if (window.caches) {
                    caches.keys().then(cacheNames => {
                        return Promise.all(
                            cacheNames.map(cacheName => {
                                return caches.delete(cacheName);
                            })
                        );
                    });
                }
            }
        });
        
        return () => {
            window.removeEventListener('beforeunload', () => {});
        };
    }, []);

    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles styles={modalStyles} />
                <MQTTProvider>
                    <AppRoutes />
                </MQTTProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;
