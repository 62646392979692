import React, { useEffect } from 'react';
import '../styles/Loader.css';

const Loader = () => {
    // Блокируем прокрутку страницы пока лоадер активен
    useEffect(() => {
        // Сохраняем текущее значение overflow body
        const originalStyle = window.getComputedStyle(document.body).overflow;
        // Блокируем прокрутку
        document.body.style.overflow = 'hidden';
        
        // Возвращаем оригинальное значение при размонтировании
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []);

    return (
        <div className="loader-container">
            <div className="simple-loader">
                <div className="spinner"></div>
                <div className="loading-text">Загрузка</div>
            </div>
        </div>
    );
};

export default Loader; 