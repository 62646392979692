import { createTheme } from '@mui/material/styles';

// Киберпанк цвета
const cyberColors = {
    primary: '#00eaff',
    primaryDark: '#00b3ff',
    secondary: '#ff00a2',
    secondaryDark: '#cc0082',
    background: '#0a0e17',
    surface: '#171f30',
    text: '#e0e0ff',
    textSecondary: '#a0a0d0',
    accent: '#00ff7b'
};

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: cyberColors.primary,
            light: '#44f2ff',
            dark: cyberColors.primaryDark,
        },
        secondary: {
            main: cyberColors.secondary,
            light: '#ff44b9',
            dark: cyberColors.secondaryDark,
        },
        error: {
            main: '#ff3366',
            light: '#ff6699',
            dark: '#cc0044',
        },
        warning: {
            main: '#ffca28',
            light: '#ffd95b',
            dark: '#ffb300',
        },
        info: {
            main: cyberColors.primary,
            light: '#44f2ff',
            dark: cyberColors.primaryDark,
        },
        success: {
            main: cyberColors.accent,
            light: '#44ffa0',
            dark: '#00cc62',
        },
        background: {
            default: cyberColors.background,
            paper: cyberColors.surface,
        },
        text: {
            primary: cyberColors.text,
            secondary: cyberColors.textSecondary,
            disabled: 'rgba(224, 224, 255, 0.38)',
        },
        divider: 'rgba(0, 234, 255, 0.2)',
    },
    typography: {
        fontFamily: [
            '"Share Tech Mono"',
            '"Russo One"',
            'monospace',
            'sans-serif',
        ].join(','),
        h1: {
            fontFamily: '"Russo One", sans-serif',
            fontSize: '2.5rem',
            fontWeight: 700,
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: '"Russo One", sans-serif',
            fontSize: '2rem',
            fontWeight: 700,
            letterSpacing: '0.04em',
            textTransform: 'uppercase',
        },
        h3: {
            fontFamily: '"Russo One", sans-serif',
            fontSize: '1.75rem',
            fontWeight: 700,
            letterSpacing: '0.03em',
        },
        h4: {
            fontFamily: '"Russo One", sans-serif',
            fontSize: '1.5rem',
            fontWeight: 700,
            letterSpacing: '0.02em',
        },
        h5: {
            fontFamily: '"Russo One", sans-serif',
            fontSize: '1.25rem',
            fontWeight: 700,
            letterSpacing: '0.01em',
        },
        h6: {
            fontFamily: '"Russo One", sans-serif',
            fontSize: '1rem',
            fontWeight: 700,
            letterSpacing: '0.01em',
        },
        button: {
            fontFamily: '"Share Tech Mono", monospace',
            fontWeight: 700,
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
        },
        body1: {
            fontFamily: '"Share Tech Mono", monospace',
            fontSize: '1rem',
            letterSpacing: '0.02em',
        },
        body2: {
            fontFamily: '"Share Tech Mono", monospace',
            fontSize: '0.875rem',
            letterSpacing: '0.02em',
        },
        caption: {
            fontFamily: '"Share Tech Mono", monospace',
            fontSize: '0.75rem',
            letterSpacing: '0.03em',
        },
        subtitle1: {
            fontFamily: '"Share Tech Mono", monospace',
            fontSize: '1rem',
            letterSpacing: '0.02em',
        },
        subtitle2: {
            fontFamily: '"Share Tech Mono", monospace',
            fontSize: '0.875rem',
            letterSpacing: '0.02em',
        },
    },
    shape: {
        borderRadius: 2,
    },
    spacing: 8,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    letterSpacing: '0.05em',
                    fontFamily: '"Share Tech Mono", monospace',
                    borderRadius: 2,
                    boxShadow: '0 0 10px rgba(0, 234, 255, 0.3)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'linear-gradient(45deg, rgba(0,234,255,0.1) 0%, rgba(0,0,0,0) 70%)',
                        zIndex: 1,
                    },
                    '&:hover': {
                        boxShadow: '0 0 15px rgba(0, 234, 255, 0.5)',
                    },
                },
                containedPrimary: {
                    background: 'linear-gradient(90deg, #00eaff, #00b3ff)',
                    color: '#0a0e17',
                    fontWeight: 700,
                },
                outlinedPrimary: {
                    borderColor: cyberColors.primary,
                    color: cyberColors.primary,
                    '&:hover': {
                        borderColor: '#44f2ff',
                        background: 'rgba(0, 234, 255, 0.1)',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    boxShadow: '0 0 15px rgba(0, 234, 255, 0.15)',
                    background: 'rgba(23, 31, 48, 0.95)',
                    border: '1px solid rgba(0, 234, 255, 0.2)',
                    backdropFilter: 'blur(5px)',
                    position: 'relative',
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '2px',
                        background: 'linear-gradient(90deg, #00eaff, #ff00a2)',
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                    fontFamily: '"Share Tech Mono", monospace',
                    letterSpacing: '0.05em',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        fontFamily: '"Share Tech Mono", monospace',
                        letterSpacing: '0.02em',
                    },
                    '& .MuiInputLabel-root': {
                        fontFamily: '"Share Tech Mono", monospace',
                        letterSpacing: '0.02em',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: '"Share Tech Mono", monospace',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: '"Share Tech Mono", monospace',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    fontFamily: '"Share Tech Mono", monospace',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: '"Share Tech Mono", monospace',
                },
                head: {
                    fontFamily: '"Russo One", sans-serif',
                    textTransform: 'uppercase',
                    letterSpacing: '0.03em',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 4,
                    boxShadow: '0 0 30px rgba(0, 234, 255, 0.3)',
                    border: '1px solid rgba(0, 234, 255, 0.2)',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                    fontFamily: '"Share Tech Mono", monospace',
                },
            },
        },
    },
});

export default theme; 