import axios from 'axios';

// Определяем baseURL на основе текущего окружения
const getBaseUrl = () => {
    // В режиме разработки используем прокси
    if (process.env.NODE_ENV === 'development') {
        return ''; // Прокси уже добавляет /api
    }

    // В production используем полный путь
    const protocol = window.location.protocol;
    const host = window.location.host;
    return `${protocol}//${host}`;
};

// Создаем экземпляр axios с базовыми настройками
const api = axios.create({
    baseURL: getBaseUrl(),
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Счетчик попыток переподключения
const retryMap = new Map();
const MAX_RETRIES = 3;

// Интерцептор для добавления токена
api.interceptors.request.use(
    (config) => {
        // Проверяем наличие токена
        const token = localStorage.getItem('jwtToken');
        if (!token && !config.url.includes('/login') && !config.url.includes('/register')) {
            // Если нет токена и это не логин/регистрация - отменяем запрос
            return Promise.reject(new Error('No auth token'));
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        
        // Добавляем заголовки для предотвращения кэширования
        config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
        config.headers['Pragma'] = 'no-cache';
        config.headers['Expires'] = '0';
        
        // Добавляем случайный параметр для предотвращения кэширования
        const timestamp = new Date().getTime();
        const url = new URL(config.url, window.location.origin);
        url.searchParams.append('_t', timestamp);
        config.url = url.pathname + url.search;
        
        // Добавляем /api к URL, если его нет
        if (!config.url.startsWith('/api')) {
            config.url = `/api${config.url}`;
        }

        // Устанавливаем id запроса
        if (!config.id) {
            config.id = Date.now() + Math.random().toString(36).substring(2, 9);
        }

        if (!retryMap.has(config.id)) {
            retryMap.set(config.id, 0);
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Интерцептор для обработки ответов
api.interceptors.response.use(
    (response) => {
        if (response.config.id) {
            retryMap.delete(response.config.id);
        }
        // Проверяем ответ на наличие данных пользователя
        if (response.data && response.data.user) {
            // Проверяем валидность данных пользователя
            const validRoles = ['admin', 'user', 'guest'];
            if (!validRoles.includes(response.data.user.role)) {
                localStorage.clear();
                window.location.replace('/login');
                return Promise.reject(new Error('Invalid user role'));
            }
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const retryCount = retryMap.get(originalRequest.id) || 0;

        // Обработка 401
        if (error.response?.status === 401 || error.response?.status === 403) {
            // Проверяем текущий путь и запрос
            const currentPath = window.location.pathname;
            const isAuthRequest = originalRequest.url.includes('/login') || 
                                originalRequest.url.includes('/register') ||
                                originalRequest.url.includes('/auth');
                                
            // Проверяем текущий процесс редиректа
            const isRedirecting = sessionStorage.getItem('redirecting');
            
            // Не выполняем очистку и редирект для авторизационных запросов или если уже идет редирект
            if (isAuthRequest || isRedirecting) {
                console.log('Ошибка авторизации в авторизационном запросе или уже идет редирект, не выполняем редирект');
                return Promise.reject(error);
            }
            
            // Если мы уже на странице логина/регистрации, просто отклоняем запрос
            if (currentPath === '/login' || currentPath === '/register') {
                console.log('Находимся на странице логина/регистрации, не выполняем редирект');
                return Promise.reject(error);
            }
            
            console.log('Получена ошибка 401/403, выполняем очистку данных и редирект');
            
            // Устанавливаем флаг для предотвращения бесконечного цикла
            if (!sessionStorage.getItem('redirecting')) {
                sessionStorage.setItem('redirecting', 'true');
                
                // Очищаем только необходимые данные авторизации вместо полной очистки
                localStorage.removeItem('jwtToken');
                localStorage.removeItem('userData');
                
                // Используем setTimeout для предотвращения гонки условий
                setTimeout(() => {
                    // Очищаем флаг редиректа перед переходом
                    sessionStorage.removeItem('redirecting');
                    window.location.replace('/login');
                }, 300); // Увеличиваем таймаут для большей стабильности
            }
            
            return Promise.reject(error);
        }

        // Повторяем запрос при ошибках сети или сервера
        if (
            (!error.response || error.code === 'ECONNABORTED' ||
             [404, 500, 502, 503, 504].includes(error.response?.status)) &&
            retryCount < MAX_RETRIES
        ) {
            retryMap.set(originalRequest.id, retryCount + 1);
            const delay = Math.min(1000 * Math.pow(2, retryCount), 10000);

            console.log(`Повторная попытка запроса (${retryCount + 1}/${MAX_RETRIES}) через ${delay}мс:`, originalRequest.url);

            await new Promise(resolve => setTimeout(resolve, delay));
            return api(originalRequest);
        }

        retryMap.delete(originalRequest.id);
        return Promise.reject(error);
    }
);

// API для работы с пользователями
export const userApi = {
    register: async (userData) => {
        try {
            const response = await api.post('/auth/register', userData);
            return response.data;
        } catch (error) {
            console.error('Ошибка регистрации:', error);
            throw error;
        }
    },

    login: async (credentials) => {
        try {
            const response = await api.post('/login', credentials);
            return response.data;
        } catch (error) {
            console.error('Ошибка входа:', error);
            throw error;
        }
    },

    getCurrentUser: async () => {
        try {
            const response = await api.get('/users/me');
            return response.data;
        } catch (error) {
            console.error('Ошибка получения данных пользователя:', error);
            throw error;
        }
    },

    // Изменение лимита времени зарядки
    updateChargingLimit: async (userId, limit) => {
        try {
            const response = await api.put(`/users/${userId}/limit`, { charging_limit: limit });
            return response.data;
        } catch (error) {
            console.error('Ошибка обновления лимита зарядки:', error);
            throw error;
        }
    },

    // Получение статистики зарядок пользователя
    getChargingStats: async (userId, startDate, endDate) => {
        try {
            let url = `/users/${userId}/stats`;

            // Добавляем параметры запроса, если они указаны
            if (startDate || endDate) {
                url += '?';
                if (startDate) url += `start=${startDate}`;
                if (startDate && endDate) url += '&';
                if (endDate) url += `end=${endDate}`;
            }

            const response = await api.get(url);
            return response.data;
        } catch (error) {
            console.error('Ошибка получения статистики:', error);
            throw error;
        }
    }
};

// API для работы с зарядками
export const chargerApi = {
    getChargers: async () => {
        try {
            console.log('[API] Запрос списка зарядок');
            const response = await api.get('/api/charger/list');
            console.log('[API] Получен ответ:', response.data);
            return response.data;
        } catch (error) {
            console.error('Ошибка получения списка зарядок:', error);
            throw error;
        }
    },

    getCharger: async (id) => {
        try {
            const response = await api.get(`/charger/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Ошибка получения зарядки ${id}:`, error);
            throw error;
        }
    },

    startCharging: async (chargerId, port, userId) => {
        try {
            const response = await api.post(`/api/charger/${chargerId}/start`, {
                port: port,
                user_id: userId
            });
            return response.data;
        } catch (error) {
            console.error(`Ошибка запуска зарядки ${chargerId}, порт ${port}:`, error);
            throw error;
        }
    },

    stopCharging: async (chargerId, port) => {
        try {
            const response = await api.post(`/api/charger/${chargerId}/stop`, {
                port: port
            });
            return response.data;
        } catch (error) {
            console.error(`Ошибка остановки зарядки ${chargerId}, порт ${port}:`, error);
            throw error;
        }
    },

    // Получение активных сессий зарядки
    getActiveSessions: async () => {
        try {
            const response = await api.get('/charger/active_sessions');
            return response.data;
        } catch (error) {
            console.error('Ошибка получения активных сессий:', error);
            throw error;
        }
    },

    // Проверка активности сессии
    checkSessionActive: async (chargerId, port) => {
        try {
            const response = await api.get(`/charger/session_active?charger_id=${chargerId}&port=${port}`);
            return response.data;
        } catch (error) {
            console.error('Ошибка проверки активности сессии:', error);
            throw error;
        }
    },

    // Получение статуса зарядки
    getChargerStatus: async (chargerId) => {
        try {
            const response = await api.get(`/api/charger/${chargerId}/status`);
            return response.data;
        } catch (error) {
            console.error(`Ошибка получения статуса зарядки ${chargerId}:`, error);
            throw error;
        }
    },

    // Добавляем функцию для сброса состояния порта
    resetPortState: async (chargerId, port) => {
        try {
            const response = await api.post(`/api/charger/${chargerId}/reset-port`, {
                port: port
            });
            return response.data;
        } catch (error) {
            console.error('Ошибка при сбросе порта:', error);
            throw error;
        }
    },

    // Добавляем функцию для сброса всех активных сессий
    resetAllSessions: async () => {
        try {
            const response = await api.post('/api/charger/reset-sessions');
            return response.data;
        } catch (error) {
            console.error('Ошибка при сбросе всех сессий:', error);
            throw error;
        }
    }
};

// Функция для проверки подключения к серверу
export const checkServerConnection = async () => {
    try {
        // Пробуем обращаться к нескольким эндпоинтам, чтобы определить, что доступно
        const endpoints = [
            { url: '/health', priority: 1 }, // Эндпоинт проверки здоровья
            { url: '/charger/list', priority: 2 }, // Эндпоинт списка зарядок
            { url: '/', priority: 3 } // Корневой эндпоинт
        ];

        // Проходим по эндпоинтам в порядке приоритета
        for (const endpoint of endpoints) {
            try {
                // Используем короткий таймаут для проверки
                const response = await axios.get(`${getBaseUrl()}${endpoint.url}`, {
                    timeout: 3000,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    }
                });

                // Если получили ответ, значит сервер доступен
                if (response.status >= 200 && response.status < 500) {
                    console.log(`[API] Сервер доступен через эндпоинт: ${endpoint.url}`);
                    return true;
                }
            } catch (endpointError) {
                // Если получили 404, возможно путь неверный, но сервер работает
                if (endpointError.response && endpointError.response.status === 404) {
                    console.log(`[API] Сервер доступен, но эндпоинт ${endpoint.url} не найден`);
                    return true;
                }

                // Пропускаем ошибку и проверяем следующий эндпоинт
                console.log(`[API] Эндпоинт ${endpoint.url} недоступен: ${endpointError.message}`);
            }
        }

        // Если все эндпоинты недоступны, возвращаем false
        console.error('[API] Сервер недоступен: все эндпоинты не отвечают');
        return false;
    } catch (error) {
        console.error('[API] Ошибка проверки подключения к серверу:', error);
        return false;
    }
};

// Добавляем функцию для проверки валидности сессии
export const validateSession = async (queryParams = '') => {
    try {
        // Проверяем, есть ли редирект в процессе
        if (sessionStorage.getItem('redirecting')) {
            console.log('Процесс редиректа уже запущен, пропускаем проверку сессии');
            return null;
        }
        
        // Проверяем, есть ли токен
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            console.log('Нет токена для проверки сессии');
            return null;
        }
        
        // Проверяем не находимся ли мы на странице логина/регистрации
        const currentPath = window.location.pathname;
        if (currentPath === '/login' || currentPath === '/register') {
            console.log('Находимся на странице логина/регистрации, пропускаем проверку сессии');
            return null;
        }
        
        // Добавляем временную метку к запросу для предотвращения кэширования
        const url = `/api/users/me${queryParams ? queryParams : `?_t=${Date.now()}`}`;
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        console.error('Ошибка при проверке сессии:', error);
        
        // Проверяем, находимся ли мы на странице логина
        const currentPath = window.location.pathname;
        if (currentPath === '/login' || currentPath === '/register') {
            console.log('Ошибка проверки сессии на странице логина, просто возвращаем null');
            return null;
        }
        
        // Проверяем, есть ли редирект в процессе
        if (sessionStorage.getItem('redirecting')) {
            console.log('Процесс редиректа уже запущен в validateSession, пропускаем повторный редирект');
            return null;
        }
        
        // Проверяем, является ли ошибка 401/403
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            // Для других страниц очищаем данные
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('userData');
        }
        
        return null;
    }
};

export default api;