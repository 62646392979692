/**
 * Сервис для управления сессиями зарядки с улучшенной защитой от потери сессий
 */
class SessionService {
    static sessions = new Map();
    static lastUpdate = null;
    static updateInterval = 5000; // 5 секунд
    static isErrorLogged = false; // Флаг для предотвращения избыточного логирования ошибок
    static listeners = new Set(); // Слушатели изменений

    // Инициализация сервиса
    static init() {
        console.log('[SessionService] Инициализирован с защитой от потери сессий');
        
        // Пропускаем инициализацию на страницах авторизации
        if (window.location.pathname === '/login' || window.location.pathname === '/register') {
            console.log('[SessionService] Пропуск инициализации на странице авторизации');
            return;
        }
        
        // Восстанавливаем сессии из localStorage
        this.restoreFromStorage();
        
        // Проверяем наличие токена перед запросом активных сессий
        const token = localStorage.getItem('jwtToken');
        if (token) {
            // Сразу запрашиваем активные сессии с сервера
            this.fetchActiveSessions();
            
            // Запускаем периодическое обновление активных сессий
            setInterval(() => {
                // Повторно проверяем токен при каждом запросе
                if (localStorage.getItem('jwtToken') && 
                    window.location.pathname !== '/login' && 
                    window.location.pathname !== '/register') {
                    this.fetchActiveSessions();
                }
            }, 10000); // Каждые 10 секунд
        } else {
            console.log('[SessionService] Нет токена, пропуск запроса активных сессий');
        }
    }

    // Восстановление сессий из localStorage
    static restoreFromStorage() {
        try {
            const savedSessions = localStorage.getItem('charging_sessions');
            if (savedSessions) {
                const parsed = JSON.parse(savedSessions);
                Object.entries(parsed).forEach(([key, session]) => {
                    this.sessions.set(key, session);
                });
                console.log('[SessionService] Восстановлено', this.sessions.size, 'сессий из localStorage');
            } else {
                console.log('[SessionService] Восстановлено 0 сессий из localStorage');
            }
        } catch (error) {
            console.error('[SessionService] Ошибка при восстановлении сессий:', error);
        }
    }

    // Получение активных сессий с сервера
    static async fetchActiveSessions() {
        try {
            // Проверяем наличие активного процесса редиректа
            if (sessionStorage.getItem('redirecting')) {
                console.log('[SessionService] Активен процесс редиректа, пропускаем запрос активных сессий');
                return;
            }
            
            // Проверка - если мы на странице логина или регистрации, не делаем запрос
            if (window.location.pathname === '/login' || window.location.pathname === '/register') {
                console.log('[SessionService] Пропуск запроса активных сессий на странице авторизации');
                return;
            }
            
            // Пытаемся получить токен
            const token = localStorage.getItem('jwtToken');
            if (!token) {
                console.log('[SessionService] Нет токена, пропуск запроса активных сессий');
                return;
            }
            
            const headers = token 
                ? { 'Authorization': `Bearer ${token}` } 
                : {};
            
            // Выполняем запрос
            const response = await fetch('/api/charger/active-sessions', { headers });
            const data = await response.json();
            
            console.log('[SessionService] Получены активные сессии (raw):', data);
            
            // Очищаем текущие сессии
            this.sessions.clear();
            
            // Обрабатываем разные форматы ответа
            const sessionsArray = Array.isArray(data) ? data : 
                                 (data && typeof data === 'object' && Array.isArray(data.data)) ? data.data : 
                                 [];
            
            console.log('[SessionService] Обработанный массив сессий:', sessionsArray);
            
            // Обновляем сессии из полученных данных
            if (sessionsArray.length > 0) {
                sessionsArray.forEach(session => {
                    if (!session || !session.charger_id || !session.port) {
                        console.warn('[SessionService] Пропущена некорректная сессия:', session);
                        return;
                    }
                    
                    const key = this.getSessionKey(session.charger_id, session.port);
                    this.sessions.set(key, {
                        userId: session.user_id,
                        chargerId: session.charger_id,
                        port: session.port,
                        startTime: session.start_time,
                        lastUpdate: Date.now()
                    });
                    console.log(`[SessionService] Добавлена сессия ${key}:`, session);
                });
            } else {
                console.log('[SessionService] Нет активных сессий от сервера');
            }
            
            // Сохраняем обновленные сессии в localStorage
            this.saveToStorage();
            
            this.notifyListeners();
            console.log('[SessionService] Всего активных сессий:', this.sessions.size);
            
        } catch (error) {
            console.error('[SessionService] Ошибка получения активных сессий:', error);
            // При ошибке очищаем сессии
            this.sessions.clear();
            this.saveToStorage();
            this.notifyListeners();
        }
    }

    // Получение сессии по ID зарядки и порту
    static getSession(chargerId, port) {
        const key = this.getSessionKey(chargerId, port);
        const session = this.sessions.get(key);
        
        if (!session) {
            return null;
        }

        // Проверяем актуальность сессии (не старше 30 секунд)
        if (Date.now() - session.lastUpdate > 30000) {
            console.log('[SessionService] Сессия устарела:', key);
            this.sessions.delete(key);
            this.saveToStorage();
            this.notifyListeners();
            return null;
        }

        return session;
    }

    // Формирование ключа сессии
    static getSessionKey(chargerId, port) {
        return `${chargerId}:${port}`;
    }

    // Сохранение сессий в localStorage
    static saveToStorage() {
        try {
            const sessionsObj = {};
            this.sessions.forEach((value, key) => {
                sessionsObj[key] = value;
            });
            localStorage.setItem('charging_sessions', JSON.stringify(sessionsObj));
            console.log('[SessionService] Сохранено', this.sessions.size, 'сессий в localStorage');
        } catch (error) {
            console.error('[SessionService] Ошибка при сохранении сессий:', error);
        }
    }

    // Добавление слушателя изменений
    static addListener(listener) {
        this.listeners.add(listener);
    }

    // Удаление слушателя
    static removeListener(listener) {
        this.listeners.delete(listener);
    }

    // Оповещение слушателей об изменениях
    static notifyListeners() {
        this.listeners.forEach(listener => {
            try {
                listener();
            } catch (error) {
                console.error('[SessionService] Ошибка в слушателе:', error);
            }
        });
    }

    // Функция проверки, есть ли активные сессии
    static hasActiveSessions() {
        return this.sessions.size > 0;
    }

    // Получение всех сессий
    static getAllSessions() {
        return Array.from(this.sessions.values());
    }

    // Глобальное событие обновления сессии
    static sendGlobalUpdate(type, session) {
        if (!session) {
            console.warn("[SessionService] Невозможно отправить глобальное событие: не указана сессия");
            return;
        }

        try {
            console.log(`[SessionService] Отправка глобального события ${type}:`, {
                type,
                session,
                timestamp: Date.now()
            });

            // Создаем и дипатчим событие
            const event = new CustomEvent('session_update', {
                detail: {
                    type,
                    session,
                    timestamp: Date.now()
                }
            });

            window.dispatchEvent(event);

            // Прямой вызов слушателей
            this.notifyListeners();

            return true;
        } catch (err) {
            console.error(`[SessionService] Ошибка отправки глобального события:`, err);
            return false;
        }
    }

    // Добавление сессии
    static addSession(chargerId, port, userId) {
        if (!chargerId || !port || !userId) {
            console.error('[SessionService] addSession вызван с неверными параметрами', { chargerId, port, userId });
            return null;
        }

        const key = this.getSessionKey(chargerId, port);

        // Если сессия уже существует, просто обновляем её
        if (this.sessions.has(key)) {
            const existingSession = this.sessions.get(key);

            // Если это тот же пользователь, просто обновляем время
            if (existingSession.userId === userId) {
                existingSession.lastUpdate = Date.now();
                this.saveToStorage();
                return existingSession;
            }

            // Если другой пользователь, создаем новую сессию
        }

        // Создаем новую сессию
        const session = {
            chargerId: Number(chargerId),
            port,
            userId: Number(userId),
            startTime: Date.now(),
            lastUpdate: Date.now()
        };

        this.sessions.set(key, session);

        // Сохраняем в localStorage
        this.saveToStorage();

        // Уведомляем слушателей
        this.notifyListeners();

        return session;
    }

    // Удаление сессии с оптимизацией
    static removeSession(chargerId, port) {
        if (!chargerId || !port) {
            console.error('[SessionService] removeSession вызван с неверными параметрами', { chargerId, port });
            return false;
        }

        const key = this.getSessionKey(chargerId, port);

        if (!this.sessions.has(key)) {
            return false;
        }

        // Сохраняем сессию для возврата
        const session = this.sessions.get(key);

        // Удаляем сессию
        this.sessions.delete(key);

        // Сохраняем в localStorage
        this.saveToStorage();

        // Уведомляем слушателей
        this.notifyListeners();

        return session;
    }

    // Добавляем новый метод для обработки события завершения сессии
    static handleSessionEnd(chargerId, port, reason) {
        if (!chargerId || !port) {
            console.error('[SessionService] Ошибка: неполные данные для завершения сессии', { chargerId, port });
            return false;
        }

        // Удаляем сессию
        const removed = this.removeSession(chargerId, port);

        if (removed) {
            console.log(`[SessionService] Сессия завершена автоматически: ${reason}`);
        }

        return removed;
    }

    static getSessionsByUserId(userId) {
        return Array.from(this.sessions.values())
            .filter(session => Number(session.userId) === Number(userId));
    }

    static hasActiveSessionForCharger(chargerId, userId) {
        return Array.from(this.sessions.values())
            .some(session =>
                Number(session.chargerId) === Number(chargerId) &&
                Number(session.userId) === Number(userId)
            );
    }

    static getSessionForPort(chargerId, port) {
        // Всегда приводим порт к верхнему регистру для консистентности
        const normalizedPort = port.toUpperCase();
        const key = `${chargerId}_${normalizedPort}`;
        return this.sessions.get(key);
    }

    static clearAllSessions() {
        // Очищаем локальное хранилище
        SessionService.sessions.clear();
        localStorage.removeItem('activeSessions');

        // Очищаем старый формат сессий
        const keys = Object.keys(localStorage);
        for (const key of keys) {
            if (key.startsWith('chargerSession_')) {
                localStorage.removeItem(key);
            }
        }

        // Уведомляем слушателей об изменениях
        SessionService.notifyListeners();

        console.log('[SessionService] Все сессии очищены');
    }

    // Добавляем метод forceCleanupSessions для принудительной очистки сессий
    static async forceCleanupSessions(chargerApi) {
        try {
            // Получаем все активные сессии
            const allSessions = SessionService.getAllSessions();
            console.log('Принудительная очистка сессий:', allSessions);
            
            // Для каждой сессии делаем запрос на завершение
            for (const [key, session] of Object.entries(allSessions)) {
                try {
                    const [chargerId, port] = key.split(':');
                    console.log(`Завершение сессии для зарядки ${chargerId} порт ${port}`);
                    
                    // Делаем запрос на завершение зарядки
                    await chargerApi.stopCharging(parseInt(chargerId), port);
                    
                    // Удаляем сессию даже если запрос не удался
                    SessionService.removeSession(parseInt(chargerId), port);
                } catch (sessionError) {
                    console.error('Ошибка при завершении сессии:', sessionError);
                    // Удаляем сессию из локального хранилища даже при ошибке
                    const [chargerId, port] = key.split(':');
                    SessionService.removeSession(parseInt(chargerId), port);
                }
            }
            
            // Очищаем все хранилище сессий
            SessionService.clearAllSessions();
            
            // Запрашиваем сброс статусов на сервере
            try {
                await chargerApi.resetAllSessions();
            } catch (resetError) {
                console.error('Ошибка при сбросе сессий на сервере:', resetError);
            }
            
            return true;
        } catch (error) {
            console.error('Ошибка при принудительной очистке сессий:', error);
            // Даже при общей ошибке очищаем хранилище сессий
            SessionService.clearAllSessions();
            return false;
        }
    }

    // Новая функция для сброса статуса конкретного порта
    static async resetPortStatus(chargerId, port, chargerApi) {
        try {
            console.log(`Сброс статуса порта ${port} для зарядки ${chargerId}`);
            
            // Удаляем сессию из локального хранилища
            SessionService.removeSession(parseInt(chargerId), port);
            
            // Делаем запрос на сброс статуса порта на сервере
            await chargerApi.resetPortState(parseInt(chargerId), port);
            
            return true;
        } catch (error) {
            console.error(`Ошибка при сбросе статуса порта ${port}:`, error);
            // Всё равно удаляем сессию из локального хранилища
            SessionService.removeSession(parseInt(chargerId), port);
            return false;
        }
    }

    /**
     * Сохраняет сессию в localStorage с резервным копированием
     * @param {number} chargerId - ID зарядной станции
     * @param {string} port - Порт (A или B)
     * @param {number} userId - ID пользователя
     */
    static saveSession(chargerId, port, userId) {
        try {
            const key = `charging_session_${chargerId}_${port}`;
            const sessionData = {
                chargerId,
                port,
                userId,
                startTime: Date.now(),
                lastUpdate: Date.now(),
                // Дополнительные поля для защиты от потери сессий
                deviceName: `Устройство ${chargerId}`,
                portLabel: `Порт ${port}`,
                createdByClient: true,
                trackingId: `${Math.random().toString(36).substring(2, 15)}_${Date.now()}`
            };

            // Сохраняем сессию в localStorage
            localStorage.setItem(key, JSON.stringify(sessionData));

            // Дублируем в sessionStorage для защиты от потери данных
            sessionStorage.setItem(key, JSON.stringify(sessionData));

            // Также сохраняем временную метку последнего обновления
            localStorage.setItem(`${key}_last_update`, Date.now().toString());

            console.log(`[SessionService] Сессия сохранена: ${JSON.stringify(sessionData)}`);

            // Создаем резервные копии в разных ключах для защиты от потери
            localStorage.setItem(`backup_${key}`, JSON.stringify(sessionData));
            localStorage.setItem(`user_${userId}_sessions`, JSON.stringify([...this.getUserSessionIds(userId), key]));

            return sessionData;
        } catch (error) {
            console.error(`[SessionService] Ошибка при сохранении сессии:`, error);
            return null;
        }
    }

    /**
     * Получает список ID сессий пользователя
     * @param {number} userId
     * @returns {Array} массив ключей сессий
     */
    static getUserSessionIds(userId) {
        try {
            const sessionsJson = localStorage.getItem(`user_${userId}_sessions`);
            if (sessionsJson) {
                return JSON.parse(sessionsJson);
            }
        } catch (e) {
            console.error('[SessionService] Ошибка при получении списка сессий пользователя:', e);
        }
        return [];
    }

    /**
     * Удаляет сессию из localStorage
     * @param {number} chargerId - ID зарядной станции
     * @param {string} port - Порт (A или B)
     */
    static removeSession(chargerId, port) {
        try {
            const key = `charging_session_${chargerId}_${port}`;

            // Сохраняем копию данных сессии для логов
            let sessionData = null;
            try {
                const sessionJson = localStorage.getItem(key);
                if (sessionJson) {
                    sessionData = JSON.parse(sessionJson);
                }
            } catch (e) {
                console.error('[SessionService] Ошибка при чтении сессии перед удалением:', e);
            }

            // Удаляем все связанные ключи
            localStorage.removeItem(key);
            sessionStorage.removeItem(key);
            localStorage.removeItem(`${key}_last_update`);
            localStorage.removeItem(`backup_${key}`);

            // Если есть данные о пользователе, удаляем сессию из его списка
            if (sessionData && sessionData.userId) {
                const userId = sessionData.userId;
                const userSessions = this.getUserSessionIds(userId);
                const updatedSessions = userSessions.filter(s => s !== key);
                localStorage.setItem(`user_${userId}_sessions`, JSON.stringify(updatedSessions));
            }

            console.log(`[SessionService] Сессия удалена для порта ${port}:`, sessionData);

            return true;
        } catch (error) {
            console.error(`[SessionService] Ошибка при удалении сессии:`, error);
            return false;
        }
    }

    /**
     * Обновляет время последнего использования сессии
     * @param {number} chargerId
     * @param {string} port
     */
    static touchSession(chargerId, port) {
        try {
            const key = `charging_session_${chargerId}_${port}`;
            const sessionJson = localStorage.getItem(key);

            if (sessionJson) {
                const session = JSON.parse(sessionJson);
                session.lastUpdate = Date.now();
                localStorage.setItem(key, JSON.stringify(session));
                localStorage.setItem(`${key}_last_update`, Date.now().toString());
            }
        } catch (e) {
            console.error('[SessionService] Ошибка при обновлении времени сессии:', e);
        }
    }

    /**
     * Получает сессию из localStorage с проверкой резервных копий
     * @param {number} chargerId - ID зарядной станции
     * @param {string} port - Порт (A или B)
     * @returns {Object|null} - Данные сессии или null, если сессия не найдена
     */
    static getSession(chargerId, port) {
        if (!chargerId || !port) {
            return null;
        }

        try {
            const key = `charging_session_${chargerId}_${port}`;

            // Обновляем время последнего использования
            this.touchSession(chargerId, port);

            // Пробуем получить сессию из localStorage
            const sessionJson = localStorage.getItem(key);
            if (sessionJson) {
                try {
                    return JSON.parse(sessionJson);
                } catch (e) {
                    console.error('[SessionService] Ошибка при парсинге сессии:', e);
                }
            }

            // Если основная копия потеряна, проверяем резервную
            const backupJson = localStorage.getItem(`backup_${key}`);
            if (backupJson) {
                try {
                    const sessionData = JSON.parse(backupJson);
                    // Восстанавливаем основную копию из резервной
                    localStorage.setItem(key, backupJson);
                    console.log('[SessionService] Сессия восстановлена из резервной копии:', sessionData);
                    return sessionData;
                } catch (e) {
                    console.error('[SessionService] Ошибка при парсинге резервной копии сессии:', e);
                }
            }

            // Если сессия не найдена ни в одном хранилище
            return null;
        } catch (error) {
            console.error(`[SessionService] Ошибка при получении сессии:`, error);
            return null;
        }
    }

    /**
     * Проверяет, существует ли активная сессия для данной зарядной станции и порта
     * @param {number} chargerId - ID зарядной станции
     * @param {string} port - Порт (A или B)
     * @returns {boolean} - true, если сессия существует
     */
    static hasActiveSession(chargerId, port) {
        return !!this.getSession(chargerId, port);
    }

    /**
     * Получает все активные сессии пользователя
     * @param {number} userId - ID пользователя
     * @returns {Array} - Массив активных сессий
     */
    static getUserSessions(userId) {
        try {
            const sessions = [];
            const sessionIds = this.getUserSessionIds(userId);

            // Получаем данные каждой сессии
            for (const key of sessionIds) {
                try {
                    const keyParts = key.split('_');
                    if (keyParts.length >= 3) {
                        const chargerId = parseInt(keyParts[1]);
                        const port = keyParts[2];

                        const session = this.getSession(chargerId, port);
                        if (session) {
                            sessions.push(session);
                        }
                    }
                } catch (e) {
                    console.error('[SessionService] Ошибка при получении данных сессии:', e);
                }
            }

            // Если по каким-то причинам список сессий пуст, ищем через перебор всех ключей
            if (sessions.length === 0) {
                for (let i = 0; i < localStorage.length; i++) {
                    const key = localStorage.key(i);
                    if (key && key.startsWith('charging_session_')) {
                        try {
                            const sessionJson = localStorage.getItem(key);
                            if (sessionJson) {
                                const session = JSON.parse(sessionJson);
                                if (session && session.userId === userId) {
                                    sessions.push(session);
                                }
                            }
                        } catch (e) {
                            console.error('[SessionService] Ошибка при разборе сессии:', e);
                        }
                    }
                }
            }

            return sessions;
        } catch (error) {
            console.error('[SessionService] Ошибка при получении сессий пользователя:', error);
            return [];
        }
    }

    /**
     * Получает время начала сессии
     * @param {number} chargerId - ID зарядной станции
     * @param {string} port - Порт (A или B)
     * @returns {number|null} - Время начала сессии в миллисекундах или null
     */
    static getSessionStartTime(chargerId, port) {
        const session = this.getSession(chargerId, port);
        return session ? session.startTime : null;
    }

    /**
     * Получает длительность сессии в секундах
     * @param {number} chargerId - ID зарядной станции
     * @param {string} port - Порт (A или B)
     * @returns {number} - Длительность сессии в секундах
     */
    static getSessionDuration(chargerId, port) {
        const startTime = this.getSessionStartTime(chargerId, port);

        if (!startTime) return 0;

        return Math.floor((Date.now() - startTime) / 1000);
    }

    /**
     * Метод для инициализации сервиса и запуска фоновых процессов
     */
    static initialize() {
        // Регистрируем сервис в глобальном объекте window для доступа из других частей приложения
        if (typeof window !== 'undefined') {
            window.SessionService = SessionService;
        }
        
        // Запускаем периодическую проверку целостности сессий
        setInterval(() => {
            try {
                this.checkSessionsIntegrity();
            } catch (e) {
                console.error('[SessionService] Ошибка при проверке целостности сессий:', e);
            }
        }, 30000); // Каждые 30 секунд

        console.log('[SessionService] Инициализирован с защитой от потери сессий');
    }

    /**
     * Проверяет целостность сессий и восстанавливает их при необходимости
     */
    static checkSessionsIntegrity() {
        const now = Date.now();
        let changed = false;

        for (const [key, session] of this.sessions.entries()) {
            // Проверяем активность сессии (исключаем слишком старые)
            if (now - session.lastUpdate > 2 * 60 * 60 * 1000) { // 2 часа без обновлений
                this.sessions.delete(key);
                changed = true;
                console.log(`[SessionService] Удалена устаревшая сессия: ${key}`);
            }
        }

        if (changed) {
            this.saveToStorage();
            this.notifyListeners();
        }
    }
}

// Инициализация сервиса при загрузке модуля
SessionService.initialize();

// Экспортируем также событие для глобальной синхронизации
export const SESSION_UPDATED_EVENT = 'session_updated';

// Экспортируем экземпляр сервиса
export default SessionService;
export { SessionService };