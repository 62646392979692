import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loader from './components/Loader';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

// Lazy loading для страниц
const Dashboard = lazy(() => import('./Pages/Dashboard'));
const Login = lazy(() => import('./Pages/Login'));
const Register = lazy(() => import('./Pages/Register'));
const Profile = lazy(() => import('./Pages/Profile'));
const Admin = lazy(() => import('./Pages/Admin'));
const Home = lazy(() => import('./Pages/Home'));
const Logout = lazy(() => import('./Pages/Logout'));
const ErrorPage = lazy(() => import('./Pages/ErrorPage'));

// Компонент для обработки ошибок загрузки страниц
const PageErrorBoundary = ({ children }) => {
    return (
        <Suspense fallback={<Loader />}>
            {children}
        </Suspense>
    );
};

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<PageErrorBoundary><Home /></PageErrorBoundary>} />
            <Route path="/login" element={
                <PageErrorBoundary>
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                </PageErrorBoundary>
            } />
            <Route path="/register" element={
                <PageErrorBoundary>
                    <PublicRoute>
                        <Register />
                    </PublicRoute>
                </PageErrorBoundary>
            } />
            <Route path="/dashboard" element={
                <PageErrorBoundary>
                    <PrivateRoute guestForbidden={true}>
                        <Dashboard />
                    </PrivateRoute>
                </PageErrorBoundary>
            } />
            <Route path="/profile" element={
                <PageErrorBoundary>
                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                </PageErrorBoundary>
            } />
            <Route path="/admin" element={
                <PageErrorBoundary>
                    <PrivateRoute adminOnly={true}>
                        <Admin />
                    </PrivateRoute>
                </PageErrorBoundary>
            } />
            <Route path="/logout" element={<PageErrorBoundary><Logout /></PageErrorBoundary>} />
            <Route path="*" element={<PageErrorBoundary><ErrorPage /></PageErrorBoundary>} />
        </Routes>
    );
};

export default AppRoutes;