import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import App from './App.jsx';
import './index.css';
import SessionService from './services/sessionService';

// Убедимся, что SessionService инициализирован
if (typeof window !== 'undefined' && !window.SessionService) {
  window.SessionService = SessionService;
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <UserProvider>
      <App />
    </UserProvider>
  </BrowserRouter>
);
