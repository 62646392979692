import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { validateSession } from '../services/api';

const PrivateRoute = ({ children, adminOnly = false, guestForbidden = false }) => {
    const { user, isLoading, logout } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        // Не запускаем проверку сессии если уже есть редирект или мы в процессе загрузки
        if (sessionStorage.getItem('redirecting') || isLoading) {
            console.log('PrivateRoute: пропуск проверки сессии из-за активного редиректа или загрузки');
            return;
        }

        // Функция для безопасной проверки сессии
        const checkSession = async () => {
            // Проверяем, есть ли активный процесс редиректа
            if (sessionStorage.getItem('redirecting')) {
                console.log('Процесс редиректа уже запущен, пропускаем проверку сессии');
                return;
            }
            
            // Проверяем, есть ли токен
            const token = localStorage.getItem('jwtToken');
            if (!token) {
                console.log('Нет токена для проверки сессии, выходим');
                // Предотвращаем двойной редирект
                if (!sessionStorage.getItem('redirecting')) {
                    sessionStorage.setItem('redirecting', 'true');
                    setTimeout(() => {
                        sessionStorage.removeItem('redirecting');
                        logout();
                    }, 300); // Увеличиваем таймаут для большей стабильности
                }
                return;
            }
            
            try {
                // Добавляем случайный параметр к запросу для предотвращения кэширования
                const timestamp = Date.now();
                const sessionData = await validateSession(`?_t=${timestamp}`);
                if (!sessionData) {
                    console.log('Сессия не валидна, выполняем logout');
                    // Предотвращаем двойной редирект
                    if (!sessionStorage.getItem('redirecting')) {
                        sessionStorage.setItem('redirecting', 'true');
                        setTimeout(() => {
                            sessionStorage.removeItem('redirecting');
                            logout();
                        }, 300); // Увеличиваем таймаут для большей стабильности
                    }
                    return;
                }

                // Проверяем соответствие данных сессии
                if (user && (
                    sessionData.id !== user.id ||
                    sessionData.role !== user.role ||
                    sessionData.email !== user.email
                )) {
                    console.error('Несоответствие данных сессии');
                    // Предотвращаем двойной редирект
                    if (!sessionStorage.getItem('redirecting')) {
                        sessionStorage.setItem('redirecting', 'true');
                        setTimeout(() => {
                            sessionStorage.removeItem('redirecting');
                            logout();
                        }, 300); // Увеличиваем таймаут для большей стабильности
                    }
                }
            } catch (error) {
                console.error('Ошибка при проверке сессии:', error);
                // Не выполняем logout при любой ошибке, только если это 401
                if (error.response && error.response.status === 401) {
                    if (!sessionStorage.getItem('redirecting')) {
                        sessionStorage.setItem('redirecting', 'true');
                        setTimeout(() => {
                            sessionStorage.removeItem('redirecting');
                            logout();
                        }, 300); // Увеличиваем таймаут для большей стабильности
                    }
                }
            }
        };

        // Проверяем сессию только если пользователь авторизован
        if (user) {
            // Проверяем сессию при монтировании и каждые 2 минуты
            checkSession();
            const interval = setInterval(() => {
                // Не выполняем проверку если уже есть активный редирект
                if (!sessionStorage.getItem('redirecting')) {
                    checkSession();
                }
            }, 2 * 60 * 1000);

            // Слушаем изменение видимости вкладки
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible' && !sessionStorage.getItem('redirecting')) {
                    checkSession();
                }
            };
            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                clearInterval(interval);
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
    }, [user, logout, isLoading]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        // Предотвращаем бесконечный редирект
        if (sessionStorage.getItem('redirecting')) {
            return <div>Перенаправление...</div>;
        }
        // При редиректе на страницу логина делаем replace для предотвращения проблем с историей
        return <Navigate to="/login" replace />;
    }

    // Проверка роли администратора
    if (adminOnly && user.role !== 'admin') {
        console.error('Попытка доступа к админ-панели без прав администратора');
        return <Navigate to="/dashboard" replace />;
    }

    // Запрет доступа для гостей
    if (guestForbidden && user.role === 'guest') {
        console.error('Попытка доступа к запрещенному разделу гостевым пользователем');
        return <Navigate to="/" replace />;
    }

    // Проверяем валидность роли
    const validRoles = ['admin', 'user', 'guest'];
    if (!validRoles.includes(user.role)) {
        console.error('Обнаружена невалидная роль пользователя');
        if (!sessionStorage.getItem('redirecting')) {
            sessionStorage.setItem('redirecting', 'true');
            setTimeout(() => {
                sessionStorage.removeItem('redirecting');
                logout();
            }, 300); // Увеличиваем таймаут для большей стабильности
        }
        return <div>Перенаправление...</div>;
    }

    return children;
};

export default PrivateRoute;